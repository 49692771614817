<template>
  <div>
    <b-tabs
      horizontal
      content-class="mt-3"
      pills
      fill
      nav-wrapper-class="col-md-6 col-16"
      nav-class="nav-top full-width"
    >
      <b-tab active>
        <template #title>
          <span class="font-weight-bold">Details</span>
        </template>
        <operativeEditorForm :operative-id="operativeId" />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Remittances</span>
        </template>
        <b-card>
          <operativeRemittances :operative-id="operativeId" />
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold ">Invoices</span>
        </template>
        <b-card v-if="isMobile == false">
          <operativeInvoices :operative-id="operativeId" />
        </b-card>
        <operativeInvoices
          v-else
          :operative-id="operativeId"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import operativeRemittances from '@/components/operatives/OperativeRemit.vue'
import operativeInvoices from '@/components/operatives/OperativeInvoice.vue'
import operativeEditorForm from '@/components/operatives/OperativeEditorForm.vue'
import { mapGetters } from 'vuex'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    BCard,
    BTabs,
    BTab,
    operativeRemittances,
    operativeInvoices,
    operativeEditorForm,
  },
  data() {
    return {
      isMobile: false,
      operativeId: -1,
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
  },
  created() {
    this.$store.dispatch('app/getTrades')
    this.$store.dispatch('app/getJobsources')
    if (this.$route.query.operativeid === undefined || this.$route.query.operativeid < 0) {
      this.opertiveId = -1
      this.$store.commit('app/resetCurrentOperative')
    } else {
      this.operativeId = Number(this.$route.query.operativeid)
    }
    /* eslint-disable */
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent) || screen.availWidth < 480) {
      this.isMobile = true
    }
    /* eslint-enable */
  },
}
</script>

<style>

</style>
