<template>
  <div
    v-if="isMobile == true"
    :class="componentCss"
  >
    <b-row class="mb-1">
      <b-col class="text-center font-weight-bolder">
        WEEK
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col class="text-center">
        {{ weekDetails }}
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col class="text-center font-weight-bolder">
        REMITTANCE
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col class="text-center">
        <b-link
          v-if="operativeRemitId !=''"
          @click="getRemittance(operativeRemitId)"
        >
          {{ operativeRemitNo }}
        </b-link>
        <span v-else>
          -
        </span>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col
        class="text-center font-weight-bolder"
      >
        VALUE
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="text-center"
      >
        £{{ remitValue }}
      </b-col>
    </b-row>
  </div>

  <div
    v-else
    class="w-100"
  >
    <b-row>
      <b-col
        cols="3"
        class="text-center"
      >
        {{ weekDetails }}
      </b-col>
      <b-col
        cols="2"
        class="text-center"
      >
        <b-link
          v-if="operativeRemitId !=''"
          @click="getRemittance(operativeRemitId)"
        >
          {{ operativeRemitNo }}
        </b-link>
        <span v-else>
          -
        </span>
      </b-col>
      <b-col
        cols="2"
        class="text-center"
      >
        £{{ remitValue }}
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BCol, BRow, BLink } from 'bootstrap-vue'
import jwt from '@/auth/jwt/sclUseJwt'

export default {

  name: 'RemitListItemComponent',
  components: {
    BCol,
    BRow,
    BLink,
  },
  props: {
    weekDetails: {
      type: String,
      required: true,
    },
    operativeRemitNo: {
      type: String,
      default: null,
      required: true,
    },
    operativeRemitId: {
      type: String,
      default: null,
      required: true,
    },
    remitValue: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {
    componentCss() {
      if (this.isMobile) {
        return 'scl-mobile'
      }
      return 'scl-desktop'
    },
  },
  created() {
    /* eslint-disable */
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent) || screen.availWidth < 480) {
      this.isMobile = true
    }
    /* eslint-enable */
  },
  methods: {
    getRemittance(remitId) {
      jwt.axiosIns.get(`/remittance/${remitId}/`, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const downloadLink = document.createElement('a')
          downloadLink.href = url
          downloadLink.setAttribute('download', `Remittance-${remitId}.pdf`)
          document.body.appendChild(downloadLink)
          downloadLink.click()
        })
        .catch(e => {
          console.error(e)
        })
    },
  },
}
</script>

<style scoped>
.scl-title-hover:hover,
.active {
  cursor: pointer;
  color: #3CB3A2;
}

.scl-desktop {
  padding: 1rem;
  flex: 1 1 auto;
  border: 2px;
  border-color: #3CB3A2;
  border-style: solid;
}
.scl-mobile {
  margin: 15px;
  padding: 1rem;
  flex: 1 1 auto;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.10);
  border-radius: 2%;
  background-color: #fff;
}
</style>
