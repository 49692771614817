<template>
  <div>
    <b-row
      v-if="isMobile == false"
      class="mb-2"
    >
      <b-col
        cols="3"
        class="text-center font-weight-bolder"
      >
        WEEK
      </b-col>
      <b-col
        cols="2"
        class="text-center font-weight-bolder"
      >
        OPERATIVE
      </b-col>
      <b-col
        cols="2"
        class="text-center font-weight-bolder"
      >
        VALUE
      </b-col>
    </b-row>
    <b-row
      v-for="(remit, index) in remittances.payments"
      :key="index"
    >
      <remitListItemComponent
        :week-details="remit.paybatch[0].year + ' / Week No.: ' + remit.paybatch[0].weekno"
        :operative-remit-no="getOperativeRemittance(remit)"
        :operative-remit-id="getOperativeRemittanceId(remit)"
        :remit-value="getRemitValue(remit)"
        class="mb-2"
      />
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol,
} from 'bootstrap-vue'
import jwt from '@/auth/jwt/sclUseJwt'
import remitListItemComponent from '@/components/operatives/ui/RemitListItemComponent.vue'

export default {

  name: 'OperativeInvoices',
  components: {
    remitListItemComponent,
    BCol,
    BRow,
  },
  props: {
    operativeId: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  data() {
    return {
      isMobile: false,
      remittances: [],
    }
  },
  created() {
    if (this.operativeId > -1) {
      jwt.axiosIns.get(`/remittances/${this.operativeId}/`)
        .then(response => {
          this.remittances = JSON.parse(JSON.stringify(response.data))
        })
        .catch(e => {
          console.error(e)
        })
    }
    /* eslint-disable */
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent) || screen.availWidth < 480) {
      this.isMobile = true
    }
    /* eslint-enable */
  },
  methods: {
    getOperativeRemittance(remit) {
      if (remit.id === undefined) {
        return ''
      }
      return String(remit.id)
    },
    getOperativeRemittanceId(remit) {
      if (remit.id === undefined) {
        return ''
      }
      return String(remit.id)
    },
    getRemitValue(remit) {
      if (remit.pay === undefined) {
        return '0.00'
      }
      return String(remit.pay)
    },
  },
}
</script>

<style scoped>

</style>
